<template>
	<div>
		<PageTitle title="个人账户" fixed></PageTitle>
		<div style="position: relative;width: 85%;margin: 15px auto auto auto;">
			<List :width="100">
				<ListItem :leftWidth="0" :rightWidth="100">
					<template #top>
						<img :src="verification">
						<p style="text-align: center">个人账户</p>
					</template>
					<template v-slot:right>
						<div class="margintb">
							<p>就诊卡号：{{idDetail.ckhm | filterCardNum}}</p>
							<p class="width50">姓名：{{idDetail.brxm}}</p>
							<p class="width50">性别：{{idDetail.brxb}}</p>
							<p class="width50">年龄：{{idDetail.nl}}</p>
							<p class="width50">民族：{{idDetail.mz}}</p>
							<p>身份证号：{{idDetail.sfzh|filterIdCardNum}}</p>
						</div>
						<div class="margintb">
							<p>性质：{{idDetail.xzmc}}</p>
						</div>
						<div class="margintb">
							<p>账户余额：{{idDetail.zhye}}元</p>
							<p>可退金额：{{idDetail.ktye}}元</p>
						</div>
					</template>
				</ListItem>
				<ListItem :leftWidth="0" :rightWidth="100">
					<template #top>
						<p>请输入退款金额</p>
					</template>
					<template #right>
						<van-field v-model="money" label="退款金额" type="number" placeholder="具体数值" input-align="center" />
					</template>
					<template #bottom>
						<p>注：退款金额不能大于实际可退金额</p>
					</template>
				</ListItem>
			</List>
			<van-button type="primary" block style="width: 100%;" @click="refundTips">确认退款</van-button>
		</div>
		<van-overlay :show="showOverlay">
			<div class="warp">
				<div class="rect">退款处理中...</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import verificationGreen from '../assets/verification-green.png';
	import {
		Refund,
		QueryAccount
	} from '@/api/user.js';

	export default {
		components: {
			PageTitle,
			List,
			ListItem,
		},
		data() {
			return {
				showOverlay: false,
				money: '',
				idDetail: {
					id: 0,
					ckhm: "",
					brxm: "",
					brxb: "",
					sfzh: "",
					brxz: "",
					xzmc: "",
					zhye: 0,
					ktye: 0,
					mrzh: "",
					sjhm: "",
					address: "",
					nl: "",
					birthday: "",
					mz: "",
					zhyxpb: "",
					sfzpb: "",
					phonePB: "",
					namepb: "",
					srbrxm: "",
					srsfzh: "",
					srphone: ""
				},
				postData: {
					openId: this.$util.string.getUrlParam(window.location.search).openid,
					ip: "172.0.0.1",
					patientName: "",
					mzhm: "",
					ckhm: "",
					attach: '', //url#后面带的内容
					fee: 0
				},
				verification: '',
			}
		},
		mounted() {
			QueryAccount(3, this.$route.params.cardId, this.$store.getters.userInfo.UserId).then(
				(data) => {
					this.idDetail = data[0];
					this.postData.patientName = data[0].brxm;
					this.postData.mzhm = data[0].mzhm;
					this.postData.ckhm = data[0].ckhm;
					if (data[0].mrzh == 1) {
						this.verification = verificationGreen;
					} else {
						this.verification = '';
					}
				},
				() => {}
			);
		},
		methods: {
			refundTips() {
				if (this.idDetail.ktye >= this.money && this.money > 0) {
					this.postData.fee = Number(this.money);
					this.$dialog.confirm({
						title: '温馨提醒',
						message: '申请退款后，后台需要一定时间处理\n退款金额会根据您原来充值订单的充值时间\n从近到远原路返回到您的账户\n请注意查收',
						confirmButtonText: '取消',
						cancelButtonText: '继续'
					}).then(() => {
						// on confirm
					}).catch(() => {
						// on cancel
						this.showOverlay = true;
						Refund(this.postData).then(
							() => {
								this.showOverlay = false;
								this.$dialog.alert({
									title: '温馨提醒',
									message: '退款申请成功，实际到账以\n微信通知为准！',
								}).then(() => {
									// on close
									this.$router.push('/accountInformation/' + this.$route.params
										.selectIndex + "/" + this.idDetail.patientId);
								});
							},
							() => {
								this.showOverlay = false;
							}
						);
					});
				} else {
					this.$toast('退款金额不符合');
				}
			}
		}
	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	.warp {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.rect {
		width: 125px;
		height: 50px;
		line-height: 50px;
		border-radius: 14px;
		text-align: center;
		background-color: #fff;
	}

	.infoContainer {
		margin-top: 15px;
		padding: 10px;

		p {
			font-size: @font-size-md;
			margin: 0;
		}

		img {
			position: absolute;
			top: -10px;
			right: -10px;
			width: 60px;
		}

		.margintb {
			margin: 10px auto auto 5px;
		}

		.width50 {
			width: 50%;
			display: inline-block;
		}

		.top p {
			text-align: center;
			margin: 0 0 10px;
		}

		.bottom p {
			font-size: @font-size-sm;
			text-align: center;
			margin-top: 10px;
		}

		.left .van-button,
		.right .van-button {
			margin: 0 0 10px;
		}

		.van-cell {
			border: 1.5px solid @green;
			padding: 4px 16px;
			border-radius: 8px;
			box-shadow: 0px 0px 5px 0px @gray-6 inset;
		}
	}
</style>
